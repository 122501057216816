import SwiperCore, { Mousewheel } from "swiper"
import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MovingElement from "@elements/MovingElement"
import parse from "html-react-parser"

// Import Swiper styles
// import "swiper/swiper.min.css"
import "swiper/css"
SwiperCore.use([Mousewheel])

const Section = styled.section`
  ${tw`relative z-10 py-10`}
`
const CarouselWrap = styled.div`
  ${tw`relative z-10`}
  & .swiper-slide:not(.big-slide) {
    ${tw`w-5/12 md:w-3/12 lg:w-2/12`}
  }
  & .swiper-slide.big-slide {
    ${tw`w-8/12 md:w-5/12 lg:w-4/12`}
  }
  & .swiper-slide.transparent {
    ${tw`w-1/12`}
  }
  & img {
    ${tw`object-contain w-full`}
  }
`
const SlideName = styled.p`
  ${tw`absolute z-10 bottom-1 left-1 md:bottom-2 md:left-2 lg:bottom-4 lg:left-4 text-symbolXl font-firacode`}
  text-shadow: ${({ darkColor }) =>
    !!darkColor ? undefined : "0px 1px 1px rgba(0,0,0,0.35)"};
  ${({ darkColor }) => (!!darkColor ? tw`text-black` : tw`text-yellow`)};
`
const Arrow = styled.div`
  ${tw`text-5xl font-black font-firacode text-yellow 2xl:text-9xl`}
`

const WpAcfCarouselModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfCarouselBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `carousel-section-${Math.floor(Math.random() * 100)}`
  const sectionId = sectionAttributes.anchor || defaultId
  return (
    <Section id={sectionId}>
      <CarouselWrap>
        <Swiper
          spaceBetween={20}
          slidesPerView={"auto"}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              spaceBetween: 10,
            },
            768: {
              spaceBetween: 20,
            },
          }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide className="transparent"></SwiperSlide>
          {blockData.slides.map((slide, i) => {
            const slide1Image = getImage(slide.image1?.localFile)
            const slide2Image = getImage(slide.image2?.localFile)
            const paddingTop = `${Math.floor(Math.random() * (5 - 1) + 1)}rem`
            const Image1Tag = slide.image1Link !== null ? "a" : "div"
            const Image2Tag = slide.image2Link !== null ? "a" : "div"
            const slide1NameDarkColor = slide.image1NameDarkColor
            const slide2NameDarkColor = slide.image2NameDarkColor
            return (
              <SwiperSlide
                key={`slide-${i}`}
                className={`${slide.slideSize === "big" ? "big-slide" : ""}`}
                style={{
                  paddingTop: `${slide.slideSize !== "big" ? paddingTop : ""}`,
                }}
              >
                {slide1Image !== null && slide1Image !== undefined && (
                  <Image1Tag
                    aria-label={`${
                      slide.image1Name !== null
                        ? parse(slide.image1Name)
                        : "carousel-image1-" + i
                    }`}
                    className="relative block slide__img"
                    {...(slide.image1Link !== null
                      ? { href: slide.image1Link.url }
                      : "")}
                  >
                    {slide.image1Name !== null && (
                      <SlideName darkColor={slide1NameDarkColor}>
                        {parse(slide.image1Name)}
                      </SlideName>
                    )}
                    <GatsbyImage
                      objectFit="cover"
                      className="w-full"
                      image={slide1Image}
                      alt=""
                    />
                  </Image1Tag>
                )}
                {slide2Image !== null && slide2Image !== undefined && (
                  <Image2Tag
                    aria-label={`${
                      slide.image1Name !== null
                        ? parse(slide.image1Name)
                        : "carousel-image2-" + i
                    }`}
                    className="relative block mt-2 slide__img md:mt-4"
                    {...(slide.image2Link !== null
                      ? { href: slide.image2Link.url }
                      : "")}
                  >
                    <GatsbyImage
                      objectFit="cover"
                      className="w-full"
                      image={slide2Image}
                      alt=""
                    />
                    {slide.image2Name !== null && (
                      <SlideName darkColor={slide2NameDarkColor}>
                        {parse(slide.image2Name)}
                      </SlideName>
                    )}
                  </Image2Tag>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </CarouselWrap>
      <MovingElement
        speed={0.3}
        direction={{
          negative: "",
          axis: "x",
        }}
        content={false}
        className="absolute z-10 transition-all duration-300 ease-linear bottom-12 right-36"
      >
        <Arrow className="arrow-symbol">{`->->>`}</Arrow>
      </MovingElement>
    </Section>
  )
}

WpAcfCarouselModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfCarouselModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfCarouselModuleBlock
